@font-face {
    font-family: 'Helvetica';
    src: url("fonts/Helvetica.ttf");
    font-weight: bold;
    text-shadow: 1px 0px 0px black;
}
@font-face {
    font-family: 'Helvetica';
    src: url("fonts/Helvetica.ttf");
}
@font-face {
  font-family: 'Forme';
  src: url("fonts/Forme/forme-grotesque-regular.ttf");
}
@font-face {
  font-family: 'Forme';
  font-weight: bold;
  src: url("fonts/Forme/forme-grotesque-semibold.ttf");
}

.article-list {
    list-style: none;
    li {
      pointer-events: all;
      position: relative;
      margin-bottom: 1/2 * $spacing-unit;
      padding-left: 3 * $spacing-unit;  
      padding: -10px;
      @include media-query($on-palm) {
        padding-left: 0;
      }
      &:last-of-type {
        margin-bottom: 0.25 * $spacing-unit;
      }
      a div {
        display: inline-block;
      }
    }
    &.socials {
      margin-top: 2*$spacing-unit;
      a {
        padding: 0 0.5em;
        &:first-of-type {
          padding-left: 0;
        }
      }
    }
    &__title-block {
      h2 {
        @include media-query($on-palm) {
          font-weight: bold;
        }
      }
    }
}

.push-left-1 {
  padding-left: 4 * $spacing-unit;
  @include media-query($on-laptop) {
    padding-left: 0;
  }
}

main {
  padding: 0 $spacing-unit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // padding-bottom: 3 * $spacing-unit;
}

.content-width {
  position: relative;
  width: $content-width;
  @include media-query($on-palm) {
    width: auto;
  }
}
.content-wide {
  width: $content-width + 4*$spacing-unit;
}
.content-offset {
  padding-left: 4*$spacing-unit;
  @include media-query($on-lg) {
    padding-left: 0;
  }
}
.cv .article-list li {
  margin-bottom: 0;
  @include media-query($on-palm) {
    padding: 0;
  }
  h3 {
    font-weight: normal;
  }
  &:last-of-type {
    margin-bottom: 0.5 * $spacing-unit;
  } 
}
.caps {
  text-transform: uppercase;
}
.item {
  margin-bottom: 4em;
}
.item_thumbnails {
  margin-top: 8em;
  img {
    height: 6em;
    margin-right: 1em;
    border-radius: 0.25em;
  }
}
.item_link {
    position: relative;
    h3 {
      text-transform: capitalize;
    }
}

.item_date {
    position: absolute;
    left: 0;
    top: 0;
    @include media-query($on-palm) {
      position: static;
      // padding-bottom: $spacing-unit;
    }
}

h1 {
  font-size: 2.5em;
  margin: 0 0 $spacing-unit/4 0;
}

h2 {
  letter-spacing: -0.0rem;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: bold;
}

p {
  line-height: 2 / 3 * $spacing-unit;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}
.post-list-heading {
  margin: $spacing-unit 0;
}
.post-header {
  margin-bottom: $spacing-unit;
  p {
    margin-bottom: $spacing-unit;
  }
}
.post-content {
  p {
    margin-bottom: 2*$spacing-unit;
  }
  img {
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
  }
  .item_description {
    text-align: justify;
    text-transform: capitalize;
  }
}
.post-bg {
  // background: rgb(237, 251, 255);
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
}
.text-center {
  text-align: center;
}

#drawingCanvas {
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}
header, .home, .resume, footer, .cv, .about {
  pointer-events: none;
  position: relative;
  z-index: 1;
  a {
    pointer-events: all;
    &:hover {
      text-decoration: underline;
    };
  }
}
.header-nav {
  flex: auto;
  h2 {
    padding-left: $spacing-unit;
    > a {
      white-space: nowrap;
    }
    @include media-query($on-sm) {
      padding: 0;
    }
  }
}

.about {
  margin-top: 1*$spacing-unit;
  li.article-list__title-block {
    margin-bottom: 2 * $spacing-unit;
  }
}
.resume {
  margin-bottom: 4*$spacing-unit;
}

.cv__picker {
  margin-bottom: $spacing-unit;
  margin-top: $spacing-unit;
}

.bubble {
  width: auto;
  pointer-events: all;
  border: 1px solid black;
  background-color: rgb(248, 248, 248);
  padding: $spacing-unit / 12 $spacing-unit / 2;
  white-space: nowrap;
  border-radius: $spacing-unit / 2;
  margin-right: $spacing-unit / 4;
  line-height: $spacing-unit;
  &.active {
    background: rgb(90, 90, 90);
    border-color:  rgb(90, 90, 90);
    a {
      color: white;
    }
  }
  &.view-site {
    position: absolute;
    right: 0;
    top: -2.6em;
    line-height: 2;
    margin: 0;
  }
}

.home-pg {
  text-align: center;
  overflow: hidden;
  margin: auto 0;
  flex: 1;
  display: flex;
  padding-bottom: 4*$spacing-unit;
  @include media-query($on-lg) {
    flex-direction: column;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: $content-width;
    padding-left: 2 * $spacing-unit;
    @include media-query($on-lg) {
      text-align: center;
    }
    @include media-query($on-laptop) {
      padding: 0;
      width: auto;
    }
    @include media-query($on-sm) {
      display: none;
    }
    h6 {
      white-space: nowrap;
      a {
        color:rgb(83, 83, 83);
        &:hover {
          text-decoration: none;
          color:rgb(35, 35, 35)
        }
      }
      &.selected {
        font-weight: bold;
        a {
          color:rgb(35, 35, 35);
        }
      }
    }
  }
  &__imgs {
    display: flex;
    align-items: center;
    @include media-query($on-lg) {
      padding-top: 2*$spacing-unit;
    }
    @include media-query($on-sm) {
      display: block;
    }
  }
  
  img {
    width: 50em;
    cursor: pointer;
  }
  &__img {
    @include media-query($on-sm) {
      margin-bottom: 2*$spacing-unit;
      h2 {
        margin-top: $spacing-unit / 2;
      }
    }
    i {
      opacity: 0;
      display: block;
      transition: 300ms all;
      padding-top: $spacing-unit / 2;
      line-height: 0;
    }
    &:hover i {
      opacity: 1;
      @include media-query($on-sm) {
        opacity: 0;
      }
    }
    &.hidden {
      display: none;
      @include media-query($on-sm) {
        display: block;
      }
    }
  }
}

h6 {
  font-size: 3.5em;
  line-height: 1.2em;
  font-weight: 300;
  @include media-query($on-laptop) {
    font-size: 2em;
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 800ms;
}

.homepage-row {
  display: flex;
  .cv {
    padding-left:  $spacing-unit;
  }
}

.home-vert-space {
  margin-top: 3 * $spacing-unit;
}

.case-study-ui-images {
  img {
    margin: 4*$spacing-unit auto;
    display: block;
    box-shadow: $spacing-unit / 8 $spacing-unit / 8 $spacing-unit / 8 rgba(0, 0, 0, 0.244);
    border-radius: $spacing-unit / 8;
    padding: 0;
  }
}