@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'Forme';
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size !default;
$base-line-height: 1.5 !default;

$spacing-unit:     2.2em !default;
$content-width:    18 * $spacing-unit;

$text-color:       rgb(47, 47, 47) !default;
$text-color-bold:  #2c2c2c !default;
$background-color: #f8f8f8 !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #545454 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    650px !default;

$on-sm:            500px !default;
$on-palm:          700px !default;
$on-laptop:        800px !default;
$on-lg:            1000px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@include media-query($on-palm) {
  .hide-palm {
    display: none !important;
  }
}

@include media-query($on-sm) {
  .hide-sm {
    display: none !important;
  }
}

.small-only {
  display: none;
}
@media screen and (max-width: $on-sm) {
  .small-only {
    display: block;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
